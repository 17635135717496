/* font import */
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@400;700&display=swap');

/* variables */
:root {
  --font-main: 'Sulphur Point', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  /* color variables */

  --primary: rgb(47, 30, 120);
  --primary-light: rgb(59, 38, 151);
  --secondary: rgb(170, 255, 241);
  --focus: rgb(39, 138, 225);
  --white: rgb(255, 255, 255);
  --green: rgb(32, 171, 88);

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-main)
}

ul {
  list-style: none;
}

body {
  line-height: 1.25;
  min-height: 100vh;
  background: rgb(80,169,207);
  background: linear-gradient(90deg, rgba(80,169,207,1) 0%, rgba(168,223,231,1) 50%, rgba(80,169,207,1) 100%);
}