
.TodoApp {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 3.2rem;
    color: var(--primary);
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    min-width: 15rem;
    width: 30vw;
    height: 2.5rem;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: var(--secondary);
    border: 2px solid var(--primary);
    font-size: 1.15rem;
    color: var(--primary)
}

.input:focus {
    outline: none;
    border: 2px solid var(--focus);
}

.btn {
    margin-left: 1rem;
    background-color: var(--primary);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.btn svg {
    color: var(--white);
    width: 100%;
    height: 100%;
    padding: 3px;
}

.btn:hover {
    cursor: pointer;
    background-color: var(--primary-light);
    transition: 0.4s;
    
}

.btn:hover svg{
    scale: 1.12;
    transition: 0.4s;
}

@media screen and (max-width: 320px) {
   
    .title {
        font-size: 2.75rem;
    }
}
@media screen and (max-width: 290px) {

    .title {
        font-size: 2.5rem;
    }
   
    .btn {
        margin-left: 0.2rem;
    }

    .input {
        min-width: 12rem;
    }
}